
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __appWithI18n from 'next-translate/appWithI18n'
// @ts-ignore
    
import '@src/styles/global.css';
import 'react-toastify/dist/ReactToastify.css';
import App from '../screens/app';

const __Page_Next_Translate__ = App;


// @ts-ignore
    export default __appWithI18n(__Page_Next_Translate__, {
// @ts-ignore
      ...__i18nConfig,
// @ts-ignore
      isLoader: true,
// @ts-ignore
      skipInitialProps: true,
// @ts-ignore
      
// @ts-ignore
    });
// @ts-ignore
  