export const holder = {
  gap: '10px',
  width: '100%',
  display: 'flex',
};

export const title = {
  marginTop: '10px',
  fontWeight: 900,
};
